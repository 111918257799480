import Vue from 'vue'
import Main from './Main.vue'
import store from './store'
import VueAnime from 'vue-animejs';
import 'animate.css/animate.min.css';
import VueYouTubeEmbed from 'vue-youtube-embed'
import VueClipboard from 'vue-clipboard2'

Vue.config.productionTip = false
export const bus = new Vue();

Vue.use(VueAnime)
Vue.use(VueYouTubeEmbed)
Vue.use(VueClipboard)

new Vue({
  render: h => h(Main),
  store,
}).$mount('#app')
