<template>
  <div>
    <FixedHeader :isComputer="isComputer"/>
    <Content />
    <Footer />
  </div>
</template>

<script>
import { bus } from "./main";

import FixedHeader from "./components/components/FixedHeader.vue";
import Footer from "./components/components/Footer.vue";

import Content from "./components/pages/Content.vue";

export default {
  data() {
    return {
      windowWidth: window.innerWidth
    };
  },
  computed: {
    isMobile() {
      return this.windowWidth <= 480;
    },
    isComputer() {
      return this.windowWidth > 1024;
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  components: {
    FixedHeader,
    Content,
    Footer,
  }
};
</script>

<style>
@import "./styles/main-2.css";
@import "./styles/zangs-only.css";

body {
  margin: 0;
  padding: 0;
}
</style>
