import Vue from 'vue'
import Vuex from 'vuex'
import test from './modules/test'
import menu from './modules/menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    test,
    menu,
  },
})