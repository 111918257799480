<template>
  <div>
    <div class="navbar">
      <div class="nav-items">
        <div class="nav-left"></div>
        <div class="nav-mid" id="name">Benjamin Widawski</div>
        <div class="nav-right">
          <div v-if="isComputer" class="nav-item">
            <div
              class="nav-cat"
              :class="isSelected('home') ? 'nav-cat-selected' : ''"
              @click="selectPage('home')"
            >Home</div>
            <!-- <div
              class="nav-cat"
              :class="isSelected('about') ? 'nav-cat-selected' : ''"
              @click="selectPage('about')"
            >About</div> -->
            <div
              class="nav-cat"
              :class="isSelected('contact') ? 'nav-cat-selected' : ''"
              @click="selectPage('contact')"
            >Contact</div>
          </div>
          <div v-else class="nav-item">
            <v-burger type="collapse" :active="isBurgerActive" @updated="onClickBurger" />
          </div>
        </div>
      </div>
    </div>
    <Modal :isClosed="!isBurgerActive" @close="closeModal" />
  </div>
</template>

<script>
import { VueScrollFixedNavbar } from "vue-scroll-fixed-navbar";
import { VBurger } from "vue-burger";
import Modal from "./Modal";

export default {
  props: ["isComputer"],
  data() {
    return {
      isBurgerActive: false,
      showBurger: false
    };
  },
  methods: {
    onClickBurger(e) {
      this.isBurgerActive = e;
    },
    isSelected(page) {
      return this.$store.state.menu.page === page;
    },
    selectPage(page) {
      this.$store.dispatch("changeValue", page);
    },
    closeModal() {
      this.isBurgerActive = false;
    }
  },
  components: {
    VueScrollFixedNavbar,
    VBurger,
    Modal
  }
};
</script>

<style>
.sticky {
  background: white;
  opacity: 1;
  box-shadow: rgba(149, 157, 165, 0.1) 0px 25px 30px -20px;
  animation: fadein 0.35s ease-in forwards;
  padding-top: 2vw;
  padding-bottom: 2vw;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.navbar {
  background: white;

  padding-top: 2vw;
  padding-bottom: 2vw;

  /* position: relative; */
  position: fixed;
  box-shadow: rgba(149, 157, 165, 0.15) 0px 25px 30px -20px;

  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;

}

@media only screen and (pointer: coarse) and (max-width: 1024px),
  screen and (max-width: 799px) {
  .navbar {
    padding-top: 2vw;
    padding-bottom: 2vw;
  }
}

.nav-item {
  line-height: 1.6em;
  font-size: 1.2rem;
  display: flex;
  flex-direction: row;
}

@media screen and (min-width: 0) and (max-width: calc(1908px - 1px)) and (orientation: landscape) {
  .nav-item {
    font-size: calc((1.2 - 1) * 1.2vw + 1rem);
  }
}

@media screen and (min-width: 0) and (max-width: calc(1908px - 1px)) and (orientation: portrait) {
  .nav-item {
    font-size: calc((1.2 - 1) * 1.2vh + 1rem);
  }
}

@media screen and (min-width: 1908px) {
  .nav-item {
    font-size: calc(1.2 * 1rem);
  }
}

@media screen and (max-width: 575px) {
  [data-mobile-nav-font-tweak="true"] .header-nav-item {
    line-height: 1.6em;
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 575px) and screen and (min-width: 0) and (max-width: calc(1908px - 1px)) and (orientation: landscape) {
  [data-mobile-nav-font-tweak="true"] .header-nav-item {
    font-size: calc((1.2 - 1) * 1.2vw + 1rem);
  }
}

@media screen and (max-width: 575px) and screen and (min-width: 0) and (max-width: calc(1908px - 1px)) and (orientation: portrait) {
  [data-mobile-nav-font-tweak="true"] .header-nav-item {
    font-size: calc((1.2 - 1) * 1.2vh + 1rem);
  }
}

@media screen and (max-width: 575px) and screen and (min-width: 1908px) {
  [data-mobile-nav-font-tweak="true"] .header-nav-item {
    font-size: calc(1.2 * 1rem);
  }
}

.nav-items {
  display: flex;
}

.nav-left {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 3vw;
}

.nav-mid {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.nav-right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: left;
  padding-right: 3vw;
}

.nav-cat {
  margin-left: 2.4vw;
}

.nav-cat:hover {
  cursor: pointer;
  border-bottom: 1px solid rgb(204, 204, 204);
}

.nav-cat-selected {
  border-bottom: 1px solid black;
}

.nav-cat-selected:hover {
  cursor: pointer;
  border-bottom: 1px solid black;
}

#name {
  font-size: 1.9rem;
  font-weight: lighter;
}

#name:hover {
  cursor: default;
}

.example {
  width: 500px;
  height: 500px;
  background: red;
}
</style> 