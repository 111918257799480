<template>
  <div class="content-wrapper">
    <div id="about">
      <div class="left-panel">
        <h3>
          Storytelling through music
          <br />
        </h3>
        <p>
          My name is Benjamin Widawski, I am a <strong>musician</strong> and a <strong>composer</strong>, based in
          Paris.
          <br />
          <br />Composing for films
          is the perfect balance between writing a music and
          <strong>telling a story</strong>. It must be subtle, but present, as it
          amplifies and alters the tone and pace of a scene.
          I thrive in creating a music that will fit an image, an <strong>atmosphere</strong>, and that will be unseparable from it.
          <!-- <br />
          <br />I have been practicing music for more than 15 years. At first, I was addicted to drums and
          I was having a blast learning it on my own, trying to recreate the rythms of my favourite songs. I then moved on 
          to playing bass guitar, than folk guitar, electric guitar, piano, and currently discovering the wonders of 
          the saxophone and the clarinet. -->
          <br />
          <br />I have worked on various projects, such as animated movies, video games, trailers, short movies and more through
          orchestral, big band jazz, electronic, rock or any musical style that helps tell the story.
          What I love is finding the
          <strong>auditive colors</strong> of a piece of art and searching for the right set of sounds to try and
          <strong>elevate the image</strong> to give it as much
          <strong>authenticity</strong>
          as it deserves.
          <br />
          <br />Don't hesitate to hit me up for a project or just to talk
          <span
            class="link"
            @click="goToContact"
          >right here</span>  ☕
        </p>
      </div>
      <div>
        <img class="photo" src="../../assets/img/general/me.jpg" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goToContact() {
      this.$store.dispatch("changeValue", "contact");
    }
  }
};
</script>

<style scoped>
#about {
  margin: 3vw;
  padding-right: 3vw;
  padding-left: 3vw;
  display: flex;
  flex-direction: row;
}

@media screen and (min-width: 0) and (max-width: 1024px) {
  #about {
    flex-direction: column;
  }

  #about > div {
    margin-bottom: 2em;
  }
}

#about > div {
  flex: 1;
}

h3 {
  line-height: 1.3328;
  font-weight: normal;
}

@media screen and (min-width: 0) and (max-width: calc(1908px - 1px)) and (orientation: landscape) {
  h3 {
    font-size: calc((2.2 - 1) * 1.2vw + 1rem);
  }
}

@media screen and (min-width: 0) and (max-width: calc(1908px - 1px)) and (orientation: portrait) {
  h3 {
    font-size: calc((2.2 - 1) * 1.2vh + 1rem);
  }
}

@media screen and (min-width: 1908px) {
  h3 {
    font-size: calc(2.2 * 1rem);
  }
}

.photo {
  width: 100%;
}

.left-panel {
  padding-right: 5em;
}

.left-panel > p {
  line-height: 2em;
}

@media screen and (min-width: 0) and (max-width: 1024px) {
  .left-panel {
    padding-right: 0;
  }
}

.link {
  color: #5e9bfc;
  font-weight: bold;
}
.link:hover {
  cursor: pointer;
}
</style>