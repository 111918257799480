<template>
  <div class="content-container">
    <div class="content-container animate__animated animate__fadeIn" v-show="page === 'home'">
      <About />
      <!-- <Presentation /> -->
      <Reel />
      <VideoBlocks />
    </div>
    <div class="content-container animate__animated animate__fadeIn" v-show="page === 'about'">
      <About />
    </div>
    <div class="content-container animate__animated animate__fadeIn" v-show="page === 'contact'">
      <Contact2 />
    </div>
  </div>
</template>

<script>
import Presentation from "../components/Presentation.vue";
import VideoBlocks from "../components/VideoBlocks.vue";
import Reel from "../components/Reel.vue";

import About from "./About";
import Contact2 from "./Contact2";

export default {
  computed: {
    page() {
      return this.$store.state.menu.page;
    }
  },
  components: {
    Presentation,
    VideoBlocks,
    About,
    Contact2,
    Reel,
  }
};
</script>

<style scoped>
.content-container {
  width: 100%;
  height: 100%;
  margin-top: 150px;
}
</style>