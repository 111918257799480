<template>
  <div>
    <!-- <About /> -->
    <div class="contact">
      <div class="sub-title">CONTACT</div>
      <hr />
      <div>
        <input
          type="text"
          class="form-input"
          placeholder="@ Your mail"
          v-model="mail"
        />
      </div>
      <div>
        <textarea
          class="form-input"
          placeholder="Your message"
          v-model="message"
        ></textarea>
      </div>
      <div>
        <button class="form-button" @click="sendMail">{{ buttonValue }}</button>
      </div>
      <div class="mail-container">
        Or send me a mail directly at
        <span class="mail">benjamin.widawski@gmail.com</span>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../api/api.js";
import About from "./About.vue";

export default {
  data() {
    return {
      mail: "",
      message: "",
      canSendMail: true,
    };
  },
  computed: {
    buttonValue() {
      return this.canSendMail ? "SEND" : "MESSAGE SENT!";
    },
  },
  methods: {
    async sendMail() {
      if (!this.canSendMail) return;
      await api.sendMail({ mail: this.mail, message: this.message });
      this.canSendMail = false;
    },
  },
  components: {
    About,
  },
};
</script>

<style scoped>
.contact {
  display: flex;
  flex-direction: column;
  padding-left: 30%;
  padding-right: 30%;
}

@media screen and (min-width: 0) and (max-width: 1024px) {
  .contact {
    padding-left: 15%;
    padding-right: 15%;
  }
}

.contact > div {
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
}

.form-input {
  border-bottom: 1px solid black;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  margin-top: 1em;
}
.contact > div > textarea {
  min-height: 8em;
}

.form-input::placeholder {
  color: rgb(201, 201, 201);
}

.form-input:active,
.form-input:hover,
.form-input:focus {
  border-color: #5e9bfc;
  outline: none;
  box-shadow: rgba(149, 157, 165, 0.15) 0px 25px 30px -20px;
}

.form-button {
  background: 0 0 #ffffff;
  border: 1px solid #2f323a;
  border-radius: 3px;
  color: #2f323a;
  font-family: "Raleway", sans-serif;
  font-size: 1.2em;
  padding: 0.6em 0.5em;
}

.form-button:hover,
.form-button:active,
.form-button:focus {
  border-color: #5e9bfc;
  color: #5e9bfc;
  cursor: pointer;
}

.sub-title {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 1.5em;
}

.mail-container {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 1.3em;
  margin-top: 2em;
}

.mail {
  color: #5e9bfc;
}
</style>