import axios from "axios"

const local = false
const url = local ? "http://localhost:6544/api" : "https://music.benjamin-widawski.com/api"

const api = {
    check: (callback) => {
        axios.get(url)
            .then(callback)
    },
    sendMail: (content) => {
        return new Promise((resolve, _) => {
            axios.post(url + "/mail/",
                content
            )
                .then(result => resolve(result.data))
        })
    },
}

export default api
