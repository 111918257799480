const CHANGE_VALUE = 'CHANGE_VALUE'

const state = {
  page: "home"
}

const actions = {
  changeValue ({ commit }, val) {
    commit(CHANGE_VALUE, val)
  },
}

const mutations = {
  [CHANGE_VALUE] (state, val) {
    state.page = val
  },
}

export default {
  state,
  actions,
  mutations
}