<template>
  <div :id="modalClass">
    <div class="content">
      <div class="modal-item" @click="clickPage('home')">Home</div>
      <!-- <div class="modal-item" @click="clickPage('about')">About</div> -->
      <div class="modal-item" @click="clickPage('contact')">Contact</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["isClosed"],
  computed: {
    modalClass() {
      return this.isClosed ? "modal-closed" : "modal";
    }
  },
  methods: {
    clickPage(page) {
      this.$store.dispatch("changeValue", page);
      this.$emit("close")
    }
  }
};
</script>

<style scoped>
#modal {
  z-index: 1;
  width: 100%;
  height: 100%;
  background: white;

  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  animation: fadein 0.25s ease-in;
}

#modal-closed {
  z-index: 1;
  width: 100%;
  height: 100%;
  background: white;

  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  animation: fadeout 0.25s ease-in forwards;
  pointer-events: none;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  to {
    opacity: 0;
  }
}

.contact {
  display: flex;
  flex-direction: columns;
}

.modal-item {
  font-size: 8.5vmin;

  margin: 3vw 5vw;
}

@media screen and (min-width: 576px) {
  .modal-item {
    font-size: 6.6vmin;
  }
}

@media screen and (min-width: 768px) {
  .modal-item {
    font-size: 6vmin;
  }
}
</style>